import { useCallback, useEffect, useState } from "react"
import services from "services"

const useProposals = ({ business_id = "1" }) => {
  const [proposals, setProposals] = useState([])

  const fetchProposals = useCallback(() => {
    services.bases.proposals
      .get({ business_id })
      .then(({ paginated_result }) => {
        setProposals(paginated_result?.data?.slice(0, 5))
      })
  }, [business_id])

  useEffect(() => {
    fetchProposals()
  }, [fetchProposals])

  return {
    proposals,
  }
}

export default useProposals
