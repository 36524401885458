import { Link } from "gatsby"
import If from "components/If"
import Card from "components/Card"
import Icon from "components/Icon"
import useProposals from "hooks/useProposals"
import EmptyState from "components/EmptyState"
import { BadgeStatus } from "components/Badge"
import { Table, TableItem, TableList } from "components/Table"

import { LINKS } from "./constants"
import "./ProposalsCard.styl"

const ProposalsCard = ({ tableHeaders = [], businessId = "1" }) => {
  const { proposals } = useProposals({ business_id: businessId })

  return (
    <Card
      className="proposals-card"
      title="Últimas propostas"
      subtitle={`${proposals?.length} propostas nos últimos 30 dias`}
    >
      <If condition={!proposals?.length}>
        <EmptyState text="Nada por aqui" />
      </If>
      <If condition={proposals?.length}>
        <Table headers={tableHeaders}>
          {proposals?.map((proposal, index) => (
            <TableList key={index} data-test="proposals-row">
              <TableItem data-test="Nome">
                {proposal.name}
                <br />
                <span className="document">{proposal.document_number}</span>
              </TableItem>
              <TableItem data-test="Data da proposta">
                {proposal.proposal_date}
              </TableItem>
              <TableItem>
                <BadgeStatus
                  data-test="Status da proposta"
                  description={proposal.status}
                  status={proposal.status_color}
                />
              </TableItem>
            </TableList>
          ))}
        </Table>
      </If>
      <Link className="proposals-card__see-all" to={LINKS[businessId]}>
        Ver todas
        <Icon name="arrow-right-full" />
      </Link>
    </Card>
  )
}

export default ProposalsCard
