import Can from "components/Can"
import PageHeader from "components/PageHeader"
import ProductCards from "components/ProductCards"
import ProposalsCard from "components/ProposalsCard"

import { HEADERS, ITEMS } from "./constants"

import "./ICDC.styl"

const ICDC = ({ pageContext: { business } }) => {
  return (
    <Can I="management" the="icdc">
      <PageHeader title="iCDC" variant="medium" />
      <section className="icdc">
        <ProductCards className="icdc__cards" items={ITEMS} />
        <ProposalsCard
          businessId={business.business_id}
          tableHeaders={HEADERS}
        />
      </section>
    </Can>
  )
}

export default ICDC
