export const ITEMS = [
  {
    title: "Propostas",
    icon: "article",
    type: "link",
    link: ROUTES.icdcProposals.path,
    permission: {
      subject: "icdc",
      action: "proposals",
    },
    hidden: "desktop",
  },
  {
    title: "Nova Proposta",
    icon: "plus",
    type: "iframe",
    iframe_info: { business_id: 13, title: "iCDC" },
    permission: {
      subject: "icdc",
      action: "sell",
    },
  },
]

export const HEADERS = ["Nome/CPF", "Data da proposta", "Status"]
